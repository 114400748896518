/* eslint-disable react/jsx-key */

/** @jsx jsx **/
import { PageProps, graphql, useStaticQuery } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import { Box, Container, Grid, Heading, Text, jsx } from 'theme-ui'
import { FluidImageObjectType } from '~/@types/models'
import { hubspotFormIds } from '~/common/hubspot'
import Layout from '~/components/layout'
import ContractDesignStudio, {
  IContractDesignStudioData,
} from '~/componentsV2/contract-design-studio'
import HeadingV2 from '~/componentsV2/heading'
import { HubspotForm } from '~/utils/hubspotForm'
import { trackCompleteRegistration } from '~/utils/marketing/track'

type QueryResult = {
  pageData: {
    frontmatter: IContractDesignStudioData
    html: string
  }
}

const BespokeServicesPage = (props: PageProps) => {
  const query = useStaticQuery<QueryResult>(graphql`
    query {
      pageData: markdownRemark(frontmatter: { templateKey: { eq: "bespokeServices" } }) {
        frontmatter {
          heroTitle
          heroBackground {
            childImageSharp {
              gatsbyImageData(width: 1920, placeholder: BLURRED, formats: [AUTO, WEBP])
            }
          }

          heroSize
          contentTitle
          content
          contentInfo
          contentCall

          formTitle

          bigImageTitle
          bigImage {
            childImageSharp {
              gatsbyImageData(width: 1440, placeholder: BLURRED, formats: [AUTO, WEBP])
            }
          }

          contacts {
            title
            info
          }

          serviceTitle

          services {
            title
            image {
              childImageSharp {
                gatsbyImageData(width: 520, placeholder: BLURRED, formats: [AUTO, WEBP])
              }
            }
          }
        }
        html
      }
    }
  `)

  const {
    location: { pathname },
  } = props

  return (
    <ContractDesignStudio
      data={query.pageData.frontmatter}
      formComponent={
        <HubspotForm
          formId={hubspotFormIds.bespokeServices as string}
          onSubmit={() => {
            trackCompleteRegistration({
              pageName: pathname,
            })
          }}
        />
      }
    />
  )
}

export default BespokeServicesPage
